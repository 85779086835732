function e5() {
  let lut = [];
  for (var i = 0; i < 256; i++) {
    lut[i] = (i < 16 ? '0' : '') + i.toString(16);
  }
  var k = [
    'x',
    'x',
    'x',
    'x',
    '-',
    'x',
    'x',
    '-',
    '4',
    'x',
    '-',
    'y',
    'x',
    '-',
    'x',
    'x',
    'x',
    'x',
    'x',
    'x',
  ];
  var u = '',
    i = 0,
    rb = (Math.random() * 0xffffffff) | 0;
  while (i++ < 20) {
    var c = k[i - 1],
      r = rb & 0xff,
      v = c == 'x' ? r : c == 'y' ? (r & 0x3f) | 0x80 : (r & 0xf) | 0x40;
    u += c == '-' ? c : lut[v];
    rb = i % 4 == 0 ? (Math.random() * 0xffffffff) | 0 : rb >> 8;
  }
  return u;
}

function isIPad() {
  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  return isIOS && (window.screen.width >= 768 && window.screen.height >= 1024);
}

// function isIPad() {
//   const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent) ||
//     (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

//   return isIOS && (window.screen.width >= 768 && window.screen.height >= 1024);
// }

function isIPadPro2() {
  const isIpad = /iPad/.test(navigator.userAgent);
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const devicePixelRatio = window.devicePixelRatio;
  const isIPadPro2_105 = isIpad && (screenWidth === 1668 || screenHeight === 2224) && devicePixelRatio === 2;
  const isIPadPro2_129 = isIpad && (screenWidth === 2048 || screenHeight === 2732) && devicePixelRatio === 2;

  return isIPadPro2_105 || isIPadPro2_129;
}

function isMobileDevice() {
  const isMobileUA = /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent);
  const isMobileScreenSize = window.innerWidth <= 835 && window.innerHeight <= 600;
  const isMobileUAData = navigator.userAgentData ? navigator.userAgentData.mobile : false;
  return isMobileUA || isMobileScreenSize || isMobileUAData || isIPadPro2() || isIPad();
}

// Assuming 768px and below is mobile layout
function isMobileLayout() {
  return (window.innerWidth <= 820 || isMobileDevice());
}



async function isAppleEnvironment() {
  if (navigator.userAgentData) {
    // Modern API
    const platform = navigator.userAgentData.platform || "";
    return platform.includes("macOS") || platform.includes("iOS");
  } else {
    // Fallback for older browsers
    const userAgent = navigator.userAgent || "";
    return /Macintosh|MacIntel|MacPPC|Mac68K|iPhone|iPad|iPod/.test(userAgent);
  }
}

// Assuming 768px and below is mobile layout
function isMobileLayoutV2() {
  return (window.innerWidth <= 835 || isMobileDevice());
}
